.UploadItem {
  min-width: 324px;
}
.UploadItem .link {
  height: 20px;
  cursor: pointer;
  font-family:  PingFangSC;
  font-weight: 400;
  font-size: 14px;
  color: #6145EF;
  letter-spacing: 0;
  white-space: nowrap;
}

@media (width <= 424px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 0) / 1) !important;
  }
}
@media (424px <= width <= 748px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 0) / 1) !important;
  }
}
@media (748px <= width <= 1072px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 1) / 2) !important;
  }
}
@media (1072px <= width <= 1416px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 2) / 3) !important;
  }
}
@media (1416px <= width <= 1760px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 3) / 4) !important;
  }
}
@media (1760px <= width <= 2084px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 4) / 5) !important;
  }
}
@media (2084px <= width <= 2408px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 5) / 6) !important;
  }
}
@media (2408px <= width <= 2732px ) {
  .UploadItem, .hiddenUploadItem {
    width: calc((100vw - 60px - 20px * 6) / 7) !important;
  }
}
